import React from 'react';

const ClutchReviewPage = () => (
  <div>
    <script
      type="text/javascript"
      src="https://widget.clutch.co/static/js/widget.js"
    ></script>{' '}
    <div
      class="clutch-widget"
      data-url="https://widget.clutch.co"
      data-widget-type="3"
      data-height="350"
      data-clutchcompany-id="1407789"
    ></div>
  </div>
);

export default ClutchReviewPage;
